import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteIcon from '@mui/icons-material/Delete';

export default function MyDataGrid(props) {
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [loadData, setLoadData] = React.useState(false);
  const [uniqueFinDates, setUniqueFinDates] = React.useState([]);
  const [filteredRows, setFilteredRows] = React.useState([]);

  // Extrae las fechas fin únicas cuando se cargue la lista de datos
  React.useEffect(() => {
    let data = getKey(props.lista[0]);
    setColumns(data);
    setRows(props.lista);
    setLoadData(true);
    
    // Extraer fechas fin únicas
    const dates = Array.from(new Set(props.lista.map(item => item.fecha_fin)));
    setUniqueFinDates(dates);
  }, [props.lista]); // Dependencia actualizada a props.lista

  // Filtrar filas por fecha fin
  const filterByDate = (date) => {
    const filtered = props.lista.filter(row => row.fecha_fin === date);
    setFilteredRows(filtered);
  };
 
 
  
  function getKey(datajson) {
  const col = []
  
  for(var i in datajson){  
        let json = {} 
        console.log(i)
        if (i==="nombres"||i==="apellidos"||i==="distrito"){
          json['width'] = 150
        }
        if (i==="empresa"){
          json['width'] = 250
        }
         
        json['field'] = i
        json['headerName'] = i
        col.push(json)
        
    }
   
  const acciones = {'field':'Aciones','headerName':'Acciones',
      renderCell: (params) => (<>
        <Link to={`/tarjeta/${params.row.slug}`}><ModeEditOutlineIcon></ModeEditOutlineIcon></Link>
        <Button ><DeleteIcon></DeleteIcon></Button>
        </>
      )}
   
  col.push(acciones)
  return col
  }
  var tabla;
  if (loadData) {
    // Usa filteredRows si hay una fecha seleccionada, de lo contrario usa todas las filas
    tabla = <DataGrid
      rows={filteredRows.length > 0 ? filteredRows : rows}
      columns={columns}
      getRowHeight={() => 'auto'}
      rowsPerPageOptions={[20, 50, 100]}
      checkboxSelection
      pagination
    />
  }
  return (
    <Box sx={{ height: 500, width: '100%' }}>
      {/* Renderiza los botones de las fechas fin únicas */}
      <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', gap: 1, mb: 2 }}>
        {uniqueFinDates.map(date => (
          <Button key={date} variant="contained" onClick={() => filterByDate(date)}>
            {date}
          </Button>
        ))}
      </Box>
      {tabla}
    </Box>
  );
}
