import React, { useState, useEffect } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';
import apiInstance from '../../../../services/kalel/ServiceApi';

export default function Grupos({ onGroupSelected }) {
  const [groups, setGroups] = useState([]);
  const [newGroupName, setNewGroupName] = useState('');

  useEffect(() => {
    // Obtener la lista de grupos cuando el componente se monta
    apiInstance.get('groups')
      .then(response => {
        setGroups(response.data);
      })
      .catch(error => {
        console.error('Error al obtener grupos:', error);
      });
  }, []);

  const createGroup = () => {
    // Crear un nuevo grupo
    apiInstance.post('groups', { name: newGroupName })
      .then(response => {
        // Actualizar la lista de grupos
        setGroups([...groups, response.data]);
        setNewGroupName('');  // Limpiar el campo
      })
      .catch(error => {
        console.error('Error al crear grupo:', error);
      });
  };

  const handleRowClick = (group) => {
    // Informar al componente padre
    onGroupSelected(group);
  };

  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre del Grupo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow 
                key={group.id} 
                onClick={() => handleRowClick(group)}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{group.id}</TableCell>
                <TableCell>{group.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TextField
        label="Nuevo Grupo"
        value={newGroupName}
        onChange={(e) => setNewGroupName(e.target.value)}
      />

      <Button variant="contained" color="primary" onClick={createGroup}>
        Crear Grupo
      </Button>
    </div>
  );
}
