import { Box, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';
function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://kalelsac.com/">
        Kalel Sac
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
                  <Typography variant="h6" align="center" gutterBottom>
                  Footer
                  </Typography>
                  <Typography
                  variant="subtitle1"
                  align="center"
                  color="text.secondary"
                  component="p"
                  >
                  SOLUCIONES INTEGRALES KALEL SAC
                  </Typography>
                  <Copyright />
              </Box>
  )
}

export default Footer