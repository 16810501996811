import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid, TextField } from '@mui/material';
import apiInstance from '../../services/kalel/ServiceApi';
import CircularProgress from '@mui/material/CircularProgress';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

 

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);
  const [licencia, setLicencia] = React.useState([]);
  const [loader, setLoader] = React.useState(null);


  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const Buscar = (params) => {
    setLoader( <Box sx={{ display: 'flex' }}>
            <CircularProgress />
          </Box>)
    apiInstance.post('oyon/consulta',params)

      .then(res => {
          setLicencia(res.data)
          setLoader(null)
      })
      .catch((error) => {
          console.log(error);
          setLoader(null)
      });
   
  }
  const Validacion = () => {
    setLicencia([])
    const params = {}
    if ( document.getElementById( "txtdni" )) {
    
        params['dni']=document.getElementById( "txtdni" ).value
    }
    else if( document.getElementById( "txtNombre" )  && document.getElementById( "txtapepaterno" ) && document.getElementById( "txtapematerno" ) ) {
        params['nombre']=document.getElementById( "txtNombre" ).value
        params['apellido_paterno']=document.getElementById( "txtapepaterno" ).value
        params['apellido_materno']=document.getElementById( "txtapematerno" ).value

    }
    else{
        return false
    }
    Buscar(params)
  };
 
  return (
    <Box sx={{ width: '90%', flexGrow: 1, p: 3   }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="DNI"  />
          <Tab label="Nombres y Apellidos"  />
           
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TextField id="txtdni" label="Dni" variant="outlined" />

      </TabPanel>
      <TabPanel value={value} index={1}>
      <TextField id="txtNombre" label="Nombres" variant="outlined"   />
      <TextField id="txtapepaterno" label="Apellido Paterno" variant="outlined"   />
      <TextField id="txtapematerno" label="Apellido Materno" variant="outlined" />

      </TabPanel>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' ,textAlign:'center'}}>
      
       <Button variant="contained" color="success" onClick={()=>{Validacion()}}>Consultar</Button>
        {loader}
    </Box>


    <Box  sx={{ width: '90%', flexGrow: 1, p: 3   }} component="form">

            {licencia && licencia.map((lic,i) => (  
      <Grid key={i}  container spacing={2} direction="row">
        
            <Grid item container key={i}  xs={12} sm={6} md={6}  spacing={2} direction="row">
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField fullWidth={true} label="Tipo Documento" variant="outlined" value={lic.tipo_documento__name} disabled />
                </Grid>
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField  fullWidth={true} label="Numero Documento" variant="outlined" value={lic.num_documento} disabled />
                </Grid>
                <Grid item     xs={12} sm={6} md={6}> 
                    <TextField  fullWidth={true} label="Nombres" variant="outlined" value={lic.nombre} disabled />
                </Grid>
                <Grid item     xs={12} sm={6} md={6}> 
                    <TextField  fullWidth={true} label="Apellidos" variant="outlined" value={lic.apellido + ' '+lic.apellido_materno}  disabled />
                
                </Grid>
                
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField fullWidth={true}  label="Categoria" variant="outlined" value={lic.categoria} disabled />

                </Grid>
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField style ={{fontWeight: 'bold'}} fullWidth={true} label={
                      <Typography variant="subtitle1" style={{fontWeight: 'bold'}}>
                        Clase
                      </Typography>
                    } variant="outlined" value='B' disabled />

                </Grid>
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField fullWidth={true}  label="NroLicencia" variant="outlined" value={lic.licencia} disabled /> 


                </Grid>
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField fullWidth={true} label="Fecha de Emision" variant="outlined" value={lic.fecha_inicio_licencia} disabled />

                </Grid>
                <Grid item     xs={6} sm={6} md={6}> 
                    <TextField fullWidth={true}  label="Fecha de Vencimiento" variant="outlined" value={lic.fecha_caducidad_licencia} disabled />
                </Grid>
                
            </Grid>
            <Grid item container xs={12} sm={6} md={6}   direction="row"  alignItems="center" justifyContent="center">
                <img src={lic.photo} alt="img" loading="lazy" style={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4,
                                          display: 'block',  width: '150px' }}/>                                     
                     
            </Grid>
            </Grid>
            ))}
   
    </Box>
    </Box>
  );
}