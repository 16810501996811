import React from 'react';
import { Typography, Container, Link } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Política de Privacidad y Términos de Uso
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Información recopilada
      </Typography>
      <Typography paragraph>
        Al utilizar "Ceval Kalel", podemos recopilar información como tu DNI y tus respuestas a las preguntas de simulacro. Esta información se utiliza exclusivamente para fines de evaluación y no se comparte con terceros.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Anuncios
      </Typography>
      <Typography paragraph>
        "Ceval Kalel" utiliza anuncios para su autofinanciamiento. Estos anuncios pueden recopilar información para personalizar la publicidad según tus intereses. Para más información sobre cómo se utiliza esta información, visita los sitios web de los proveedores de anuncios.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Uso de la aplicación
      </Typography>
      <Typography paragraph>
        "Ceval Kalel" es una herramienta de simulacro para la obtención de licencias de conducir de motos. Al usar esta aplicación, aceptas utilizarla de manera responsable y solo para fines educativos.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Cambios a esta política
      </Typography>
      <Typography paragraph>
        Podemos actualizar esta política de privacidad en cualquier momento. Te recomendamos revisarla periódicamente. Al continuar usando "Ceval Kalel" después de cualquier cambio, aceptas dichos cambios.
      </Typography>
      
      <Typography variant="h5" gutterBottom>
        Contacto
      </Typography>
      <Typography paragraph>
        Si tienes preguntas sobre esta política, contáctanos a través de nuestro sitio web <Link href="https://kalelsac.com">kalelsac.com</Link> o envíanos un correo electrónico a <Link href="mailto:gerenciageneral@kalelsac.com">gerenciageneral@kalelsac.com</Link>. Estamos ubicados en Calle 8 270, Comas 15311.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
