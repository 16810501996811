// usePermissions.js
import { useState, useEffect } from 'react';

const GetPermisos = () => {
  const [userPermissions, setUserPermissions] = useState({
    is_superuser: false,
    user_permissions: [],
  });

  useEffect(() => {
    // Aquí puedes también obtener los permisos desde localStorage cada vez que el componente padre se monte
    // o cuando algún otro estado/prop cambie y necesites recargar los permisos.
    const storedPermissions = JSON.parse(localStorage.getItem('permisos')) || {};
    setUserPermissions(storedPermissions);
  }, []); // El array vacío indica que este useEffect se ejecutará solo al montar el componente.

  const isSuperuser = userPermissions.is_superuser;
  const userPermissionCodes = (userPermissions.user_permissions || []).map(perm => perm.id);

  const hasPermission = (route) => {
    if (isSuperuser) return true;  // Si es superusuario tiene acceso a todo.
    return userPermissionCodes.includes(route);  // Verifica si el permiso está en los permisos del usuario.
  }

  return { hasPermission }; // Retorna el método para ser utilizado.
}

export default GetPermisos;
