import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Colaborador from '../../../components/catalogos/Colaborador';
import Servicio from '../../../components/catalogos/Servicios';
import apiInstance from '../../../services/kalel/ServiceApi';
import SearchIcon from '@mui/icons-material/Search';

const RegisterLicencia = ({onClose, open}) => {
  const [saldo, setSaldo] = useState('');
  const [montoPagado, setMontoPagado] = useState('');
  const [montoTotal, setMontoTotal] = useState('');
  const [fisrtName, setFirstName] = useState('');
  const [openToast, setOpenToast] = useState(false);
  const [severity, setSeverity] = useState('success');
  const [mensajeToast, setMensajeToast] = useState({});

  const [lastName, setLastName] = useState('');
  const [dni, setDni] = useState('');
  const [numeroLicencia, setNumeroLicencia] = useState('');
  const [selectedServicio, setSelectedServicio] = useState(null);
  const [selectedColaborador, setSelectedColaborador] = useState(null);
  const handleCloseRegistro = () => { onClose(); };
  const handleSelectionServicio = (servicio) => {
    setSelectedServicio(servicio);

  };
  const handleColaborador = (colaborador) => {
    setSelectedColaborador(colaborador);
    setMontoTotal(colaborador.monto)
    console.log(colaborador)

  };
  const handleSearch = () => {
    apiInstance.post('usuario/kalelSearchPerson', { identificador: dni })
      .then(res => {
        console.log(res);
        if (Array.isArray(res.data.persona)) {
          // La respuesta es un array
          setFirstName(res.data.persona[0].first_name);
          setLastName(res.data.persona[0].last_name);
        } else {
          // La respuesta es un objeto JSON
          setFirstName(res.data.persona.first_name);
          setLastName(res.data.persona.last_name);
        }
        setOpenToast(true);
        setMensajeToast({ "status": "encontrado" });
        setSeverity('success');
      })
      .catch((error) => {
        setOpenToast(true);
        setMensajeToast(error);
        setSeverity('info');
      });
  };
  const handleSubmit = async (event) => {
    console.log("guardar")
    event.preventDefault();
  
    try {
      const formData = new FormData(event.target);
      const response = await apiInstance.post("/kalelPago/", formData);
      console.log(response.data);
      handleCloseRegistro();
    } catch (error) {
      console.error("Error al guardar los datos:", error);
    }
  };
  

  
  const fechaActual = new Date().toISOString().substr(0, 10);
  
  // Actualiza el saldo automáticamente
  useEffect(() => {
    const montoTotalValue = parseFloat(montoTotal) || 0;
    const montoPagadoValue = parseFloat(montoPagado) || 0;
    setSaldo((montoTotalValue - montoPagadoValue).toFixed(2));
  }, [montoTotal, montoPagado]);

  // Actualiza el número de licencia automáticamente
  useEffect(() => {
    setNumeroLicencia(`O-${dni}`);
  }, [dni]);

  if (!open) {
    return null;
  }
  return (
    <div>
      
      <Dialog open={open} onClose={handleCloseRegistro} maxWidth="md" fullWidth BackdropProps={{
        onClick: (event) => {
          event.stopPropagation();
        },
      }}
      disableEscapeKeyDown>
        <DialogTitle>Formulario</DialogTitle>
        <DialogContent>
        <form id="registerLicenciaForm" onSubmit={handleSubmit}>
          <Grid container spacing={2}> 
            {/* Campos del formulario */}
            <Grid item xs={12} sm={6}>
            <Servicio onSelectionChange={handleSelectionServicio} selectedValue={selectedServicio} />
            <input type="hidden" name="servicio" value={selectedServicio ? selectedServicio.id : ''} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField    label="DNI"    fullWidth    name="dni"    value={dni}    onChange={(e) => setDni(e.target.value)}
              InputProps={{
                              endAdornment: (
                              <InputAdornment position="end">
                                  <IconButton onClick={handleSearch}>
                                  <SearchIcon />
                                  </IconButton>
                              </InputAdornment>
                              ),
                          }}        
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Apellidos" fullWidth name='apellidos' value={lastName}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Nombres" fullWidth name='nombres' value={fisrtName}/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Colaborador onSelectionChange={handleColaborador} selectedServicio={selectedServicio} />
              <input type="hidden" name="colaborador" value={selectedColaborador ? selectedColaborador.id : ''} />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField label="Curso s/" fullWidth name='curso'/>
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Costo"
                fullWidth
                value={montoTotal}
                onChange={(e) => setMontoTotal(e.target.value)}
                name="monto_total"
              />
            </Grid>
            <Grid item xs={12} sm={3}>
            <TextField
              label="A cuenta"
              fullWidth
              value={montoPagado}
              onChange={(e) => setMontoPagado(e.target.value)}
              name="monto_pagado"
            />
            </Grid>
            <Grid item xs={12} sm={3}>
            <TextField
              label="Saldo"
              fullWidth
              value={saldo}
              readOnly
              name="saldo"
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Fecha de inscripción"
                type="date"
                defaultValue={fechaActual}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Categoría Anterior" fullWidth name='categoria_anterior'/>
            </Grid>
            <Grid item xs={12} sm={6}>
            
              <TextField label="Fecha de Nacimiento" type="date" fullWidth InputLabelProps={{
                shrink: true,
              }} />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField label="Dirección" fullWidth name='direccion' />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField label="Observación" fullWidth multiline rows={4} name='observacion'/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Costo Examen" fullWidth name='monto_examen_medico' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Tipo de pago" fullWidth name='tipo_pago' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Número Comprobante" fullWidth name='numero_comprobante' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Grupo" fullWidth  name='grupo_sanguineo'/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Factor Sanguíneo" fullWidth name='factor_sanguineo'/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Departamento" fullWidth name='departamento'/>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Correo" fullWidth name='correo' />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Nacionalidad" defaultValue="Peruana" fullWidth name='nacionalidad'/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              label="Número de licencia"
              fullWidth
              value={numeroLicencia}
              readOnly
              name="numero_licencia"
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField label="Restricción" fullWidth name='restriccion' />
            </Grid>
          </Grid>
          </form>
        </DialogContent>       
        <DialogActions>
          <Button onClick={handleCloseRegistro}>Cancelar</Button>
          <Button type="submit" form="registerLicenciaForm">Guardar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RegisterLicencia;
