import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';

import apiInstance from '../services/kalel/ServiceApi';
import {  Divider,List,Toolbar,IconButton,Box,CssBaseline} from '@mui/material';
 
 
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { MainListItems, SecondaryListItems } from '../components/modulos/listItems';
import Rutas from './Route';
import Utilities from '../services/kalel/Utilities';
import Footer from '../components/AppBarComponents/Footer';
import MyAppBar from '../components/AppBarComponents/MyAppBar';

 
const drawerWidth = 240;
 
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const mdTheme = createTheme();

export default function App() {
  const [open, setOpen] = React.useState(false); 
  const disables =false; 
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const titulo = localStorage.getItem("domain_titulo")
  const domainImage = localStorage.getItem("domainImage")
  const logueados = Utilities.userSession() 
  const toggleDrawer = () => {
    setOpen(!open);
  };
 
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseSession = () => {
 
    apiInstance.post('usuario/logout',{})  

      .then(res => {
        localStorage.clear();
        window.location.href="./login"
      })
      .catch((error) => {
        localStorage.clear();
        window.location.href="./login"

      });
  };
  if(logueados){
    return (
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <MyAppBar  open={open}
            toggleDrawer={toggleDrawer}
            domainImage={domainImage}
            titulo={titulo}
            handleOpenUserMenu={handleOpenUserMenu}
            anchorElUser={anchorElUser}
            handleCloseUserMenu={handleCloseUserMenu}
            handleCloseSession={handleCloseSession} />
           
          <Drawer variant="permanent" open={open} sx={{                 
                  ...(disables && { display: 'none' }),
                }}>
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
              }}
            >
              <IconButton onClick={toggleDrawer} color='primary'>
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List component="nav">
              <MainListItems domain={window.location.host}></MainListItems>
              <Divider sx={{ my: 1 }} />
              <SecondaryListItems></SecondaryListItems>
            </List>
          </Drawer> 
          <Box component="main" sx={{ flexGrow: 1, p: 2 ,background:'#f1f9f9'}}>
            <Toolbar />
            <Rutas />
           </Box>

        </Box>
        {!disables &&  <Footer />}
      </ThemeProvider>
    ) 
  }
  else{
    return (
      <Rutas />
    )
    }
}

 