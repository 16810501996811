import React from 'react'

const Redireccionar = () => {
  const host = window.location.host

    console.log(host)
  if(host==='redirect.licenciadeconducirmunioyon.com' || 'redirect.localhost:3000'){
    window.location.href="http://licenciadeconducirmunioyon.com/oyon"

  }
  return (
    <div>Esta pagina solo sirve para redireccionar</div>
  )
}

export default Redireccionar