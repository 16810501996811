import { Fab } from '@mui/material';
import React from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Mensajeria = () => {
  const fabStyle = {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: 'green', // Establecer el color de fondo en verde
  };

  return (
    <div>
      <a
        href="https://wa.me/958480178/?text=Hola%20KALEL%20:"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Fab
          aria-label="add"
          style={fabStyle}
        >
          <WhatsAppIcon />
        </Fab>
      </a>
    </div>
  );
};

export default Mensajeria;
