import React, { useState, useEffect } from 'react';
import { Button, TextField, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, TablePagination } from '@mui/material';
import apiInstance from '../../../../services/kalel/ServiceApi';

export default function Permisos({ onPermisoSelected }) {
  const [permisos, setPermisos] = useState([]);
  const [newPermisopName, setNewPermisopName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Ejemplo: 10 filas por página
  const [searchValue, setSearchValue] = useState('');
  useEffect(() => {
    apiInstance.get('permissions')
      .then(response => {
        setPermisos(response.data);
      })
      .catch(error => {
        console.error('Error al obtener Permisos:', error);
      });
  }, []);

  const createPermiso = () => {
   const codename = newPermisopName.replace(/\s+/g, '_').toLowerCase();
    apiInstance.post('permissions/', { name: newPermisopName, content_type:1, codename:codename })
      .then(response => {
        setPermisos([...permisos, response.data]);
        setNewPermisopName('');
      })
      .catch(error => {
        console.error('Error al crear Permiso:', error);
      });
  };

  const handleRowClick = (permiso) => {
    onPermisoSelected(permiso);
  };

  return (
    <div>
    <TextField
       label="Buscar Permiso"
       value={searchValue}
       onChange={(e) => setSearchValue(e.target.value)}
    />

    <TableContainer component={Paper}>
       <Table>
          <TableHead>
             <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre del Permiso</TableCell>
             </TableRow>
          </TableHead>
          <TableBody>
             {permisos
                .filter(permiso => permiso.name.toLowerCase().includes(searchValue.toLowerCase()))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((permiso) => (
                   <TableRow 
                      key={permiso.id} 
                      onClick={() => handleRowClick(permiso)}
                      style={{ cursor: 'pointer' }}
                   >
                      <TableCell>{permiso.id}</TableCell>
                      <TableCell>{permiso.name}</TableCell>
                   </TableRow>
             ))}
          </TableBody>
       </Table>
    </TableContainer>

    <TablePagination
       component="div"
       count={permisos.filter(permiso => permiso.name.toLowerCase().includes(searchValue.toLowerCase())).length}
        page={page}
        onPageChange={(event, newPage) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => setRowsPerPage(+event.target.value)}
    />

    <TextField
       label="Nuevo Permiso"
       value={newPermisopName}
       onChange={(e) => setNewPermisopName(e.target.value)}
    />

    <Button variant="contained" color="primary" onClick={createPermiso}>
       Crear Permiso
    </Button>
 </div>
);
}
