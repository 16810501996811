import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Divider, Paper, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import FormularioTarjeta from './FormularioTarjeta';
import Utilities from '../../services/kalel/Utilities';
import apiInstance from '../../services/kalel/ServiceApi';

const DetalleTarjeta = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCardLoaded, setCardLoaded] = useState(false);
  const [isCardValid, setCardValid] = useState(false);
  const [card, setCard] = useState(null);
  const { id } = useParams();
  const cardUrl = `usuario/tarjeta/${id}`;

  const fetchCard = async () => {
    try {
      const response = await apiInstance.get(cardUrl);
      console.log(response.data);
      setCard(response.data);
      setCardValid(true);
    } catch (error) {
      console.error(error);
      setCardValid(false);
    } finally {
      setCardLoaded(true);
    }
  };

  useEffect(() => {
    const isLoggedIn = Utilities.userSession();
    setIsLoggedIn(isLoggedIn);
    fetchCard();
  }, []);

  const handleDelete = () => {
    console.log("eliminar", id);
    apiInstance.delete(cardUrl);
  };

  const handleDeactivate = () => {
    console.log("desactivar", id);
    apiInstance.patch(cardUrl,{state: false});
  };

   
  return (
    <Paper>
      {isLoggedIn && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="10vh"
        >
          <Button variant="contained" color="error" onClick={handleDelete}>
            <DeleteIcon /> ELIMINAR
          </Button>
          <Button variant="contained" color="success" onClick={handleDeactivate}>
            <ModeEditIcon /> DESACTIVAR
          </Button>
          
        </Box>
      )}
      <Divider />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="10vh"
      >
        <Typography variant="h2" gutterBottom>
          CARNÉ VIAL
        </Typography>
      </Box>
      <Divider />
      {isCardLoaded && isCardValid ? (
        <FormularioTarjeta tarjeta={card} login={isLoggedIn} />
      ) : (
        "no encontrado"
      )}
    </Paper>
  );
};

export default DetalleTarjeta;
