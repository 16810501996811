import {  useParams } from 'react-router-dom'
import apiInstance from '../../../services/kalel/ServiceApi';
import React from 'react'
import ModeloTarjeta from './ModeloTarjeta';

function TarjetaConstatacion() {
  const [loadTarjeta, setLoadTarjeta] = React.useState(false);
  const [validTarjeta, setValidTarjeta] = React.useState(false);
  const [tarjeta, setTarjeta] = React.useState(null);

  const { id } = useParams()
  const urlTajeta = 'usuario/tarjeta/'+id
  
  let obtenerTarjeta=async()=>{
    await apiInstance.get(urlTajeta,{})
    .then(response =>{
      console.log(response.data)
      setTarjeta(response.data)
      setValidTarjeta(true)
      setLoadTarjeta(true)
      
    })
    .catch(error =>{
      setValidTarjeta(false)
      setLoadTarjeta(true)
    })
  }
  React.useEffect(() => { 
    obtenerTarjeta() 
  },[0]);

  if(loadTarjeta && validTarjeta){
  return (
    <ModeloTarjeta tarjeta={tarjeta} />
  );
}
else{
  return (<>cargando....</>)
}
}

export default TarjetaConstatacion;