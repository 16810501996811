import React, { useState, useEffect } from "react";
import useAxios from "../../../../hooks/UseApi";
import apiInstance from "../../../../services/kalel/ServiceApi";
import {
  Box,
  Button,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import Servicio from "../../../../components/catalogos/Servicios";

const Colaboradores = () => {
    const [refresh, setRefresh] = useState(false);
    const { data: colaboradores, loading, error, refetch } = useAxios("get", "kalelColaborador", {}, refresh); // Añadir refetch
    const [selectedColaborador, setSelectedColaborador] = useState(null);
    const [editMode, setEditMode] = useState(false);
    const [selectedServicio, setSelectedServicio] = useState(null); // Almacena el servicio seleccionado

    const handleSelectionServicio = (servicio) => {
      if (servicio) {
        setSelectedServicio(servicio);
      } else {
        setSelectedServicio(null);
      }
    };
 
    
    
     
    const handleCreate = async (colaborador) => {
        try {
          await apiInstance.post("/kalelColaborador/", colaborador);
          setRefresh(!refresh); // Llamar a refetch en lugar de fetchColaboradores
        } catch (error) {
          console.error("Error al crear el colaborador", error);
        }
      };
        
      const handleUpdate = async (colaborador) => {
        try {
          await apiInstance.put(`/kalelColaborador/${colaborador.id}/`, colaborador);
          setRefresh(!refresh);
          setEditMode(false);
          setSelectedColaborador(null);
        } catch (error) {
          console.error("Error al actualizar el colaborador", error);
        }
      };
      
      const handleDelete = async (id) => {
        try {
          await apiInstance.delete(`/kalelColaborador/${id}/`);
          setRefresh(!refresh);
        } catch (error) {
          console.error("Error al eliminar el colaborador", error);
        }
      };
      
      const renderForm = () => (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            const formData = new FormData(e.target);
            let colaboradorData = Object.fromEntries(formData.entries());
      
            if (selectedServicio) {
              colaboradorData = { ...colaboradorData, servicio: selectedServicio.id };
            }
      
            if (editMode) {
              handleUpdate({ ...selectedColaborador, ...colaboradorData });
            } else {
              handleCreate(colaboradorData);
            }
          }}
        >
          <Box display="flex" flexDirection="column" gap={2} marginBottom={3}>
            <TextField
              name="nombres"
              label="Nombres"
              value={selectedColaborador?.nombres || ""}
              onChange={(e) =>
                setSelectedColaborador({ ...selectedColaborador, nombres: e.target.value })
              }
            />
            <TextField
              name="apellidos"
              label="Apellidos"
              value={selectedColaborador?.apellidos || ""}
              onChange={(e) =>
                setSelectedColaborador({ ...selectedColaborador, apellidos: e.target.value })
              }
            />
            <Servicio
              onSelectionChange={handleSelectionServicio}
              selectedValue={editMode ? selectedColaborador?.servicio : null}
            />
            <TextField
              name="monto"
              label="Monto"
              value={selectedColaborador?.monto || ""}
              onChange={(e) =>
                setSelectedColaborador({ ...selectedColaborador, monto: e.target.value })
              }
            />
            <Button type="submit" variant="contained" color="primary">
              {editMode ? "Actualizar" : "Crear"}
            </Button>
          </Box>
        </form>
      );
      
      // Agrega un useEffect que se ejecute cuando cambie selectedColaborador
      useEffect(() => {
        if (selectedColaborador && editMode) {
          setSelectedServicio({ id: selectedColaborador.servicio });
        } else {
          setSelectedServicio(null);
        }
      }, [selectedColaborador, editMode]);
      
  // Renderiza el formulario de colaborador
  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Colaboradores
      </Typography>
      {renderForm()}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Servicio</TableCell>
              <TableCell>Nombres</TableCell>
              <TableCell>Apellidos</TableCell>
              <TableCell>Monto</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {colaboradores &&
              colaboradores.map((colaborador) => (
                <TableRow key={colaborador.id}>
                  <TableCell>{colaborador.id}</TableCell>
                  <TableCell>{colaborador.servicio}</TableCell>
                  <TableCell>{colaborador.nombres}</TableCell>
                  <TableCell>{colaborador.apellidos}</TableCell>
                  <TableCell>{colaborador.monto}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        setSelectedColaborador(colaborador);
                        setEditMode(true);
                      }}
                    >
                      Editar
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleDelete(colaborador.id)}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
};

export default Colaboradores;