import {   Autocomplete, FormControl, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAxios from '../../hooks/UseApi';


const Colaborador = ({onSelectionChange,selectedServicio }) => {
  const [selectedValue, setSelectedValue] = useState(null);
  
  // Función para construir los parámetros de consulta
  const buildQueryParams = () => {
    if (selectedServicio) {
      return { id_servicio: selectedServicio.id };
    }
    return {};
  };
  useEffect(() => {
    setSelectedValue(null);
  }, [selectedServicio]);
  const queryParams = buildQueryParams();
  const { data, loading, error } = useAxios('get', 'kalelColaborador/', queryParams, selectedServicio);
  

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    if (onSelectionChange) {
      onSelectionChange(newValue);
    }
  };
  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error al cargar los datos</div>;
  }
  
  return (   
    <FormControl fullWidth={true}>
    <Autocomplete
        id="slc_colaborador"
        options={data}
        getOptionLabel={(option) => `${option.id} - ${option.nombres} ${option.apellidos}`}
        value={selectedValue}
        onChange={handleChange}
 
     
        renderInput={(params) => <TextField {...params} label="Colaborador" />}

      />
        
    </FormControl>
  );
};

export default Colaborador;
