import React from 'react';
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { Toolbar, IconButton, Typography, Badge, Tooltip, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const drawerWidth = 240;
const StyledAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  

export default function MyAppBar({ open, toggleDrawer, domainImage, titulo, handleOpenUserMenu, anchorElUser, handleCloseUserMenu, handleCloseSession }) {
  const disables =false; 
  const titleStyles = {
    fontStyle: 'italic',
    fontSize: '2rem',
  };
  return (
    <StyledAppBar position="absolute" open={open} id="appbar">
       <Toolbar
              sx={{
                pr: '24px', 
                background:'#e8eafb',
                justifyContent: 'space-between' 
              }}
            >
              <IconButton
                edge="start"
                color="primary"
                aria-label="open drawer"
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                  ...(disables && { display: 'none' }),
                  
                }}
              >
                <MenuIcon />
              </IconButton>
              <Typography
                  component="h1"
                  variant="h6"
                  color="inherit"
                  noWrap
                  sx={{ 
                      ...titleStyles, 
                      color: 'green', 
                      display: 'flex', 
                      alignItems: 'center',
                      justifyContent: 'center' 
                  }}  
              >
                  <img src={domainImage} alt="Logo" style={{ marginRight: '8px', height: '64px' }} />
                  {titulo} 
              </Typography>
              <IconButton color="primary">
                <Badge badgeContent={4} color="secondary">
                  <NotificationsIcon />
                
                </Badge>
                <Tooltip title="Opciones">
                  <AccountCircleIcon onClick={handleOpenUserMenu} sx={{ p: 0 }}/>
                    
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem key="perfil" >
                        <Typography>Perfil</Typography>
                  </MenuItem>
                  <MenuItem key="close" onClick={handleCloseSession}>
                        <Typography>Cerrar Session</Typography>
                  </MenuItem>
                </Menu>
              </IconButton>
              
            </Toolbar>
    </StyledAppBar>
  );
}
