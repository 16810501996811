import {   FormControl,  InputLabel, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useAxios from '../../hooks/UseApi';


const Documento = ({onChangeTipoDoc}) => {
  const [selectedValue, setSelectedValue] = useState(1);

  const { data, loading, error } = useAxios('get', 'kalelDocumento',{});
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    
  };
  
  useEffect(() => {
    if (onChangeTipoDoc) {
      onChangeTipoDoc(selectedValue);
    }
  }, [selectedValue]);

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error al cargar los datos</div>;
  }
  return (   
    <FormControl fullWidth={true}>
        <InputLabel id="tipo_documento">Escoja un Documento</InputLabel>
        <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedValue}
            onChange={handleChange}
            >

            {data.map((val, i) => (                                         
                <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>                     
            ))} 
        </Select>
    </FormControl>
  );
};

export default Documento;
