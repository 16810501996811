import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';

function SelectVariants({ lista, callbackDistrito, callback }) {
  const [select, setSelect] = useState([]);
  const [listaDistrito, setListaDistrito] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [idDistrito, setIdDistrito] = useState(0);

  useEffect(() => {
    const listasDistrito = lista.map((setting, i) => ({
      idDistrito: setting.id,
      label: setting.name,
      position: i
    }));
    setListaDistrito(listasDistrito);
  }, [lista]);

  const handleChangeSelect = (value) => {
    if (value != null) {
      setSelect(value.position);
      setIdDistrito(value.idDistrito);
      callbackDistrito(value);

      const listasEmpresas = lista[value.position].empresas.map((empresa) => ({
        idEmpDis: empresa.id,
        label: empresa.name_empresa,
        idEmpresa: empresa.empresa
      }));
      setListaEmpresas(listasEmpresas);
    } else {
      callbackDistrito(value);
    }
  };

  const handleChangeSecond = (value) => {
    setSelect(value);
    callback(value);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', gap: '16px' }}>
      <Box sx={{ flex: 1 }}>
        <FormControl variant="standard" sx={{ width: '100%' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo1"
            options={listaDistrito}
            sx={{ width: '100%' }}
            onChange={(event, value) => handleChangeSelect(value)}
            renderInput={(params) => <TextField {...params} label="Distrito" />}
          />
        </FormControl>
      </Box>
      <Box sx={{ flex: 1 }}>
        <FormControl variant="filled" sx={{ width: '100%' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={listaEmpresas}
            sx={{ width: '100%' }}
            onChange={(event, value) => handleChangeSecond(value)}
            renderInput={(params) => <TextField {...params} label="Empresa" />}
          />
        </FormControl>
      </Box>
    </Box>
  );
}

export default SelectVariants;
