import React from 'react';

function MicrosoftIdentityAssociationFile() {
  const content = {
    associatedApplications: [
      {
        applicationId: "87d1c8ef-dad1-4559-8f34-08ddc23443b4"
      }
    ]
  };

  return (
    <pre>
      {JSON.stringify(content, null, 2)}
    </pre>
  );
}

export default MicrosoftIdentityAssociationFile;
