import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ManageRolesUser from './ManageRolesUser';
import ManageRolesGroups from './ManageRolesGroups';

export default function ManageRoles() {
  const [activeView, setActiveView] = useState('groups');  // 'groups' o 'users'

  return (
    <div>
      <h1>Panel de Administración</h1>

      {/* Botones para cambiar la vista activa */}
      <Button
        variant={activeView === 'groups' ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => setActiveView('groups')}
      >
        Administración por grupos
      </Button>

      <Button
        variant={activeView === 'users' ? 'contained' : 'outlined'}
        color="primary"
        onClick={() => setActiveView('users')}
      >
        Administración de permisos por usuarios
      </Button>

      {activeView === 'groups' && <ManageRolesGroups />}
      {activeView === 'users' && <ManageRolesUser />}
    </div>
  );
}
