import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer, CircularProgress, Typography } from '@mui/material';
import apiInstance from '../../../../services/kalel/ServiceApi';

export default function GroupPermissions({ groupId }) {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setLoading(true);
    setError(null);

    // Obtener permisos cuando el componente se monta o cuando `groupId` cambia
    apiInstance.get(`groups/${groupId}`)
      .then(response => {
        setPermissions(response.data.permissions);
      })
      .catch(error => {
        console.error('Error al obtener permisos:', error);
        setError('Hubo un error al obtener los permisos.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupId]);

  const deletePermission = (id) => {
    // Eliminar un permiso
    apiInstance.delete(`permissions/${id}`)
      .then(() => {
        // Actualizar la lista de permisos
        setPermissions(permissions.filter(p => p.id !== id));
      })
      .catch(error => {
        console.error('Error al eliminar el permiso:', error);
      });
  };

  const editPermission = (id) => {
    // Aquí puedes navegar al formulario de edición o abrir un modal, etc.
    console.log(`Editar permiso con ID: ${id}`);
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Nombre</TableCell>
                <TableCell>Código</TableCell>
                <TableCell>Tipo de Contenido</TableCell>
                <TableCell>Acciones</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {permissions.map((perm) => (
                <TableRow key={perm.id}>
                  <TableCell>{perm.id}</TableCell>
                  <TableCell>{perm.name}</TableCell>
                  <TableCell>{perm.codename}</TableCell>
                  <TableCell>{perm.content_type}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="primary" onClick={() => editPermission(perm.id)}>
                      Editar
                    </Button>
                    <Button variant="contained" color="secondary" onClick={() => deletePermission(perm.id)}>
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}
