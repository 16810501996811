import React from 'react';
import ReactDOM from 'react-dom/client';
import Rutas from './routes/Route';

import { 
  BrowserRouter} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import Provider from './context/userProvider';
import App from './routes/App';
 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <Provider>
    <BrowserRouter>
      <App />
    
    </BrowserRouter>
    

  </Provider>

  </React.StrictMode>
);

reportWebVitals();
