import {
  Box,
  Card,
  CardMedia,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import constatacionFooter from '../../../assets/images/constatacionfooter.jpeg';
import React, { useState } from 'react';

function Gallery({ imageUrls }) {
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={2} mt={4}>
        {imageUrls.map((imageUrl, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ textAlign: 'center' }}>
            <Card
              sx={{ maxWidth: '100%', cursor: 'pointer' }}
              onClick={() => handleOpen(imageUrl.photo)}
            >
              <CardMedia
                component="img"
                height="120"
                image={imageUrl.photo}
                alt={`Imagen ${index}`}
              />
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '50%',
            maxWidth: '100%',
            maxHeight: '100%',
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            overflow: 'auto',
            '@media (max-width: 600px)': {
              width: '90%',
            },
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Ampliada"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const ModeloTarjeta = ({ tarjeta }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Paper
        sx={{
          backgroundColor: '#004a89', // Fondo azul
          padding: 3,
          borderRadius: 8,
        }}
      >
         <Box mt={2} display="flex" justifyContent="center">
          <img src={tarjeta.logo} alt="Logo" style={{ maxWidth: '50%', height: 'auto' }} />
      </Box>

      <Box mt={2} flex="1" textAlign="center"  >
      <Typography color="#ffff">
        CONSTANCIA DE APROBACIÓN DE CONSTATACIÓN DE CARACTERÍSTICAS
      </Typography>
    </Box>

    <Table sx={{ border: '5px solid #ffff', borderCollapse: 'collapse', color: '#ffffff' }}>
  <TableBody >
    <TableRow >
      <TableCell sx={{ border: '5px solid #ffff', padding: '10px', borderTop: 'none' }}>
        <Typography color= "white" fontWeight="fontWeightBold">PLACA</Typography>
        <Typography color= "white"  variant="caption">{tarjeta.placa}</Typography>
      </TableCell>
      <TableCell sx={{ border: '5px solid #ffff', padding: '10px', borderTop: 'none', borderLeft: 'none' }}>
        <Typography  color= "white" fontWeight="fontWeightBold">PROPIETARIO</Typography>
        <Typography color= "white" variant="caption">{tarjeta.nombres} {tarjeta.apellidos}</Typography>
      </TableCell>
      <TableCell sx={{ border: '5px solid #ffff', padding: '10px', borderTop: 'none' }} rowSpan={2}>
        <Typography color= "white" variant="h6" fontWeight="fontWeightBold">{tarjeta.estado_vigencia}</Typography>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell sx={{ border: '5px solid #ffff', padding: '10px', borderTop: 'none' }}>
        <Typography color= "white"  fontWeight="fontWeightBold">VIGENCIA</Typography>
        <Typography color= "white"  variant="caption">{tarjeta.vigencia} / {tarjeta.fin_vigencia}</Typography>
      </TableCell>
      <TableCell sx={{ border: '5px solid #ffff', padding: '10px', borderTop: 'none', borderLeft: 'none' }}>
        <Typography color= "white"  fontWeight="fontWeightBold">PERSONA JURIDICA</Typography>
        <Typography color= "white"  variant="caption">{tarjeta.empresa}</Typography>
      </TableCell>
    </TableRow>
  </TableBody>
</Table>

      <Box mt={2} display="flex" justifyContent="center">
        <Gallery imageUrls={tarjeta.foto_vehiculo} />
       </Box>
      <Box mt={2} display="flex" justifyContent="center">
          <Card sx={{ maxWidth: 250 }}>
            <CardMedia component="img" height="150" image={constatacionFooter} alt="Constatacion Footer" />
          </Card>
      </Box>


      </Paper>
    </Box>
  );
};

export default ModeloTarjeta;
