import React ,{useState} from 'react';
import { Card, CardContent, Typography, Button } from '@mui/material';
import { AddCircle, Visibility, List, Warning } from '@mui/icons-material';
import RegisterLicencia from './RegisterLicencia';



const cardStyles = {
  backgroundColor: '#F9EBEA',
  margin: '10px',
  minWidth: '250px',
  textAlign: 'center'
};

const buttonStyles = {
  margin: '10px'
};

const Index = () => {
    const [openModalRegistro, setOpenModalRegistro] = useState(false);


    const handleAgregarRegistro = () => {
        setOpenModalRegistro(true);
      };
    const handleCloseRegistro = () => {
        setOpenModalRegistro(false);
      };
  return (
    <div>
      <Card style={cardStyles}>
        <CardContent>
          <Typography variant="h5">Agregar Registro</Typography>
          <Button variant="contained" color="primary" style={buttonStyles} onClick={handleAgregarRegistro}>
            <AddCircle /> Agregar
          </Button>
          <RegisterLicencia onClose={handleCloseRegistro} open={openModalRegistro} />
        </CardContent>
      </Card>

      <Card style={{...cardStyles, backgroundColor: '#D5F5E3'}}>
        <CardContent>
          <Typography variant="h5">Ver Abonos</Typography>
          <Button variant="contained" color="primary" style={buttonStyles}>
            <Visibility /> Ver
          </Button>
        </CardContent>
      </Card>

      <Card style={{...cardStyles, backgroundColor: '#EBF5FB'}}>
        <CardContent>
          <Typography variant="h5">Listar Registros</Typography>
          <Button variant="contained" color="primary" style={buttonStyles}>
            <List /> Listar
          </Button>
        </CardContent>
      </Card>

      <Card style={{...cardStyles, backgroundColor: '#FCF3CF'}}>
        <CardContent>
          <Typography variant="h5">Ver Pagos Pendientes</Typography>
          <Button variant="contained" color="primary" style={buttonStyles}>
            <Warning /> Ver
          </Button>
        </CardContent>
      </Card>
     
    </div>
  );
}

export default Index;
