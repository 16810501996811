const token = JSON.parse(localStorage.getItem('token')) ;
var AuthStr = false;
var logueado = false;
var date_today = new Date();

if (token == null) {
    logueado = false;
} else {
    var date_expiry = new Date(token.expiry);
    if (date_expiry > date_today.getTime()) {
        AuthStr = `Token ${token.token}`;
        logueado = true;
    } else {
        localStorage.removeItem('token');
        logueado = false;
    }
}

class Utilities { 
    userSession() {        
        return logueado;
    }
    getToken() {        
        return AuthStr;
    }
}

const utilitiesInstance = new Utilities();
export default utilitiesInstance;
