import {  Autocomplete,Box, Button, Checkbox, Divider, FormControlLabel, 
    Grid, InputAdornment,   TextField, 
    Typography,FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import React, { useRef, useState,forwardRef  } from 'react';
import Modal from '@mui/material/Modal';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/zh-cn';
import './style.css';
import Documento from './catalogos/Documento';
import apiInstance from '../services/kalel/ServiceApi';
import ToastMessagge from './ToastMessagge';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  };
const UserCursoVial = ({ open, onClose, distrito,empresa }) => {
    const [isActive, setIsActive] = useState(true);
    const [openToast, setOpenToast] = useState(false);
    const [mensajeToast, setMensajeToast] = useState({});
    const [severity, setSeverity] = useState('success');
    const [selectedValue, setSelectedValue] = useState('MAÑANA');
    const [dni, setDni] = useState('');
    const [fisrtName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [formal, setFormal] = useState(true);
    const [CPJ, setCPJ] = useState('');
    const [fechafin, setFechaFin] = useState(dayjs());

    const handleChangeTurno = (event) => { setSelectedValue(event.target.value);  };
    const handleFirstName = (event) => { setFirstName(event.target.value);  };
    const handleLastName = (event) => { setLastName(event.target.value);  };
    const handleDni = (event) => { setDni(event.target.value);  };
    const handleCPJ = (event) => { setCPJ(event.target.value);  }; 
    const handleChange = (event) => {  setIsActive(event.target.checked);  };  
    const handleChangeFormal = (event) => {  setFormal(event.target.checked);  };  
    const handleClose = () => { onClose(); };
    const handleCloseToast = () => { setOpenToast(false); };
    const handleClear = () => { 
        setCPJ(''); 
        setFirstName(''); 
        setLastName(''); 
        setDni(''); 
    };
    const handleFechaFinChange = (newValue) => {
      setFechaFin(newValue);
    };
    const handleSave = () => { 
        apiInstance.post('usuario/kalelCreateStudentAula',{
            identificador:dni,
            first_name:fisrtName,
            last_name:lastName,
            cpj:CPJ,
            id_empresa_distrito:empresa.idEmpDis,
            turno:selectedValue,
            formal:formal,
            type_document:1,
            fecha_fin:fechafin.format("YYYY-MM-DD")
        })
        .then(res => {
            setOpenToast(true)
            setMensajeToast({"status":"registro guardado exitosamente"})
            setSeverity('success')
        })
        .catch((error) => {
            setOpenToast(true)
            setMensajeToast(error)
            setSeverity('error')
        });
    };
    const handleSearch = () => {
      apiInstance.post('usuario/kalelSearchPerson', { identificador: dni })
        .then(res => {
          console.log(res);
          if (Array.isArray(res.data.persona)) {
            // La respuesta es un array
            setFirstName(res.data.persona[0].first_name);
            setLastName(res.data.persona[0].last_name);
          } else {
            // La respuesta es un objeto JSON
            setFirstName(res.data.persona.first_name);
            setLastName(res.data.persona.last_name);
          }
          setOpenToast(true);
          setMensajeToast({ "status": "encontrado" });
          setSeverity('success');
        })
        .catch((error) => {
          setOpenToast(true);
          setMensajeToast(error);
          setSeverity('info');
        });
    };
    
  
  if (!open) {
    return null;
  }


  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
    >
    <Box sx={{ ...style }}>
 
    <Typography variant="h6" gutterBottom>
        Registrar Carné Seguridad Vial :  
      </Typography>
      <Typography variant="h6" gutterBottom>
        {distrito.label}-{empresa.label}
      </Typography>
      {openToast && <ToastMessagge severity={severity} open={openToast} msg={mensajeToast} onClose={handleCloseToast}/>}
      
    <Divider />
        <Typography variant="h6" gutterBottom>  DATOS FIJOS   </Typography>
    <Divider />

    <Grid container spacing={3}>
        <Grid item xs={6} sm={4}>                    
            <FormControl fullWidth={true}>
            <InputLabel id="turno">Escoja un turno</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedValue}
                onChange={handleChangeTurno}
                >
                <MenuItem value={'MAÑANA'}>MAÑANA</MenuItem>
                <MenuItem value={'TARDE'}>TARDE</MenuItem>
                <MenuItem value={'NOCHE'}>NOCHE</MenuItem>
            </Select>
            </FormControl>
        </Grid>
        <Grid item xs={6} sm={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>                
            <DatePicker
              label="Fecha Fin"
              value={fechafin}
              onChange={handleFechaFinChange}
              renderInput={(params) => <TextField {...params} />}
            />
            </LocalizationProvider>
        </Grid>

        <Grid item xs={6} sm={4}>
             <Documento />

        
        </Grid>
    </Grid>
    <Divider />
        <Typography variant="h6" gutterBottom>  DATOS A INGRESAR   </Typography>
    <Divider />
    <Grid container spacing={3}>

        <Grid item xs={12}>
          <TextField
            required
            id="dni"
            value={dni}
            onChange={handleDni}
            label="Nro Documento"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
            InputProps={{
                endAdornment: (
                <InputAdornment position="end">
                    <IconButton onClick={handleSearch}>
                    <SearchIcon />
                    </IconButton>
                </InputAdornment>
                ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="first_name"
            value={fisrtName}    
            onChange={handleFirstName}

            label="Nombres"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"            
            label="Apellidos"
            onChange={handleLastName}
            value={lastName}
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
       
     
       
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            label="CPJ"
            value={CPJ}
            onChange={handleCPJ}
            fullWidth
            variant="standard"
          />
        </Grid>

      
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                    checked={isActive}
                    onChange={handleChange}
                    color="secondary"
                    name="saveAddress"
                    value="yes"
                    />
                }
                label="Activo"
                />
            <FormControlLabel
                control={
                    <Checkbox
                    checked={formal}
                    onChange={handleChangeFormal}
                    color="secondary"
                    name="saveAddress"
                    value="yes"
                    />
                }
                label="Formal"
                />
        </Grid>
      </Grid>
         
        <Button variant="contained" onClick={handleSave}>GUARDAR</Button>
        <Button variant="contained" onClick={handleClose}>CERRAR</Button>
        <Button variant="contained" onClick={handleClear}>LIMPIAR</Button>
    </Box>
    </Modal>
  );
};

export default UserCursoVial;
