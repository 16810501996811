import { Box, Divider, Grid, Typography } from '@mui/material'
import React from 'react'
import BusquedaOyon from './busqueda'

const ConsultaOyon = () => {
  const [matches, setMatches] = React.useState(window.matchMedia("(min-width: 768px)").matches );
  React.useEffect(() => {
    const handler = e => setMatches(e.matches);
    window.matchMedia("(min-width: 768px)").addEventListener('change', handler);
    

  },[]);
  return (<> 
    <Box component="main" sx={{ flexGrow: 1, p: 3 ,bgcolor: '#352FA0'}}>  

        <Grid container  direction="row">
            <Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
            {matches && (<> 
                            <Typography variant="h4" component="h2" color = 'white'>
                               Municipalidad Provincial de Oyon
                            </Typography>
                            </> )}
                {!matches && (<> 
                    <Typography variant="h6" component="h6" color = 'white'>
                        Municipalidad Provincial de Oyon
                    </Typography>
                    </> )}
            </Grid>
            <Grid item xs={3} sm={3} md={3}>
                <img
                    src={`https://api.kalelsac.com/media/municipalidad/logo_oyon.png`}
                    
                    srcSet={`${`https://api.kalelsac.com/media/municipalidad/logo_oyon.png`} 320w, 
                                ${`https://api.kalelsac.com/media/municipalidad/logo_oyon.png`} 680w,
                                 ${`https://api.kalelsac.com/media/municipalidad/logo_oyon.png`}   960w,
                                  ${`https://api.kalelsac.com/media/municipalidad/logo_oyon.png`} 1980w`}
                    alt='banner'
                    loading="lazy"
                />
            </Grid> 
            <Grid item xs={7} sm={7} md={8}>
                {matches && (<><Typography variant="h3" component="h2" color = 'white'>
                                Consulta de Licencia de Conducir Vehiculo Menor 
                            </Typography>
                            
                            </> )}
                {!matches && (<>
                    <Typography variant="h6" component="h6" color = 'white'>
                        Consulta de Licencia de Conducir Vehiculo Menor 
                    </Typography>
                    
                    </> )}
                               
            </Grid> 
            <Grid item xs={2} sm={2} md={1}>
                <img
                    src={`https://api.kalelsac.com/media/municipalidad/mototaxi_oyon.png`}
                    
                    srcSet={`${`https://api.kalelsac.com/media/municipalidad/mototaxi_oyon.png`} 320w,
                     ${`https://api.kalelsac.com/media/municipalidad/mototaxi_oyon.png`} 680w,
                      ${`https://api.kalelsac.com/media/municipalidad/mototaxi_oyon.png`} 960w, ${`https://api.kalelsac.com/media/municipalidad/mototaxi_oyon.png`} 1980w`}
                    alt='banner'
                    loading="lazy"
                />                
            </Grid> 
        </Grid>
        </Box>

        <Divider/>
         
            <BusquedaOyon/>
        <Divider/>
         
        <Box sx={{ bgcolor: '#352FA0', p: 6 }} component="footer">
            <Grid container spacing={2} direction="row">
                <Grid item container xs={6} sm={6} md={6} direction="row" > 
                    <Grid item   xs={12} sm={12} md={12}  > 
                        {matches && (<Typography variant="h3" component="h2" color = 'white'>
                        MUNICIPALIDAD PROVINCIAL DE OYON
                        </Typography> )}
                        {!matches && (<Typography variant="h7" component="h6" color = 'white'>
                        MUNICIPALIDAD PROVINCIAL DE OYON

                        </Typography> )} 
                    </Grid> 

                    <Grid item   xs={12} sm={12} md={12}  > 
                    {matches && (<Typography variant="h4" component="h2" color = 'white'>
                    Plaza de Armas N°102, Oyón, Peru
                    </Typography> )}
                     {!matches && (<Typography variant="h7" component="h6" color = 'white'>
                     Plaza de Armas N°102, Oyón, Peru
                    </Typography> )}
                    
                    </Grid>
                    
                    <Grid item   xs={12} sm={12} md={12}  >  
                        <Typography
                        variant="subtitle1"
                        align="center"
                        color='white'
                        
                        >
                            (01) 2372016
                        </Typography>
                    </Grid>

                </Grid>
                <Grid item container xs={6} sm={6} md={6}  > 
                {matches &&
                // eslint-disable-next-line
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMunicipalidadprovincialdeOyon&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=147798566971367"
                 width="420" height="200"  
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                }
                {!matches &&  
                // eslint-disable-next-line
                <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FMunicipalidadprovincialdeOyon&tabs=timeline&width=340&height=500&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=147798566971367"
                 width="170" height="170"   
               allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>}
                </Grid>
        </Grid>
                  
        </Box>
        
      
    
 
  </>

  )
}

export default ConsultaOyon