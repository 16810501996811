import React from 'react';
import { Navigate } from 'react-router-dom';
import Utilities from '../services/kalel/Utilities';

function withAuth(Component) {
  return function ProtectedRoute(props) {
    const logueado = Utilities.userSession();
    if (logueado) {
      return <Component {...props} />;
    } else {
      return <Navigate to="/login" />;
    }
  }
}

export default withAuth;
