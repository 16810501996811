import React, { useState, useEffect } from "react";
import apiInstance from "../../../../services/kalel/ServiceApi";
import Permisos from "./Permisos";
import Grupos from "./Grupos";
import { Grid, Button, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Paper, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

function UserPermissions({ userId }) {
  const [userPermissions, setUserPermissions] = useState([]);
  const [userGroups, setUserGroups] = useState([]);
  const [newPermissionId, setNewPermissionId] = useState("");
  const [newGroupId, setNewGroupId] = useState("");
  const [action, setAction] = useState("agregar"); // Valor predeterminado: agregar
    // Estados para la selección actual de permiso o grupo y su descripción
    const [selectedPermission, setSelectedPermission] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);

    // Estado para controlar si mostrar los componentes Permisos y Grupos
    const [showPermisos, setShowPermisos] = useState(false);
    const [showGrupos, setShowGrupos] = useState(false);
    const [selectedPermisosCart, setSelectedPermisosCart] = useState([]);
    const [selectedGruposCart, setSelectedGruposCart] = useState([]);
    
    useEffect(() => {
        if (selectedPermission) {
            if (!selectedPermisosCart.some(perm => perm.id === selectedPermission.id)) {
                // Si el permiso seleccionado no está ya en el "carrito", añádelo
                setSelectedPermisosCart(prevPermisos => [...prevPermisos, selectedPermission]);
            }
        }
    }, [selectedPermission]);
    useEffect(() => {
        if (selectedGroup) {
            if (!selectedGruposCart.some(group => group.id === selectedGroup.id)) {
                setSelectedGruposCart(prevGrupos => [...prevGrupos, selectedGroup]);
            }
        }
    }, [selectedGroup]);
     
    // Elimina el permiso del carrito
    const removePermisoFromCart = (permisoId) => {
        setSelectedPermisosCart(prevPermisos => prevPermisos.filter(perm => perm.id !== permisoId));
    };

    // Elimina el grupo del carrito
    const removeGroupFromCart = (groupId) => {
        setSelectedGruposCart(prevGrupos => prevGrupos.filter(group => group.id !== groupId));
    };

  const fetchPermisos = ()=>{
    apiInstance.get(`/user_permissions/${userId}/`)
      .then((response) => {
        const userData = response.data;
        setUserPermissions(userData.user_permissions);
        setUserGroups(userData.groups);
      })
      .catch((error) => {
        console.error("Error al obtener los permisos y grupos del usuario", error);
      });
  }
  useEffect(() => {
    // Realiza una solicitud para obtener los permisos y grupos del usuario
    fetchPermisos()
  }, [userId]);

  
  const handleSave = () => {
    apiInstance.post(`/assign_permissions_and_groups/`,{user_id : userId,permissions:selectedPermisosCart,groups:selectedGruposCart})
    .then((response) => {
        fetchPermisos()
    })
    .catch((error) => {
      console.error("Error al obtener los permisos y grupos del usuario", error);
    });
    };

  return (
    <Paper style={{ padding: '20px', margin: '20px' }}> 
    <Grid container spacing={3}>
        <Grid item xs={3}>
            <Typography variant="h5">Permisos Individuales:</Typography>
            <Button variant="contained" color="primary" onClick={() => setShowPermisos(!showPermisos)}>
                {showPermisos ? "Ocultar Permisos" : "Ver Permisos"}
            </Button>
            <List>
                {userPermissions.map((permission) => (
                    <ListItem key={permission.id}>
                        <ListItemText primary={permission.name} />
                    </ListItem>
                ))}
            </List>
        </Grid>

        <Grid item xs={3}>
            <Typography variant="h5">Grupos del Usuario:</Typography>
            <Button variant="contained" color="primary" onClick={() => setShowGrupos(!showGrupos)}>
                {showGrupos ? "Ocultar Grupos" : "Ver Grupos"}
            </Button>
            <List>
                {userGroups.map((group) => (
                    <ListItem key={group.id}>
                        <ListItemText primary={group.name} secondary={
                            <List>
                                {group.permissions.map((permission) => (
                                    <ListItem key={permission.id}>
                                        <ListItemText primary={permission.name} />
                                    </ListItem>
                                ))}
                            </List>
                        } />
                    </ListItem>
                ))}
            </List>
        </Grid>
        <Grid item xs={3}>
            {showPermisos && <Permisos onPermisoSelected={(permission) => setSelectedPermission(permission)} />}
        </Grid>

        <Grid item xs={3}>
            {showGrupos && <Grupos onGroupSelected={ (group) => setSelectedGroup(group)} />}
        </Grid>
    </Grid>
    <Grid container spacing={1}>
        <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>Permisos a Agregar:</Typography>
                <List>
                    {selectedPermisosCart.map((permiso) => (
                        <ListItem key={permiso.id}>
                            <ListItemText primary={permiso.name} />
                            <ListItemSecondaryAction>
                                <IconButton edge="end" aria-label="delete" onClick={() => removePermisoFromCart(permiso.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}
                </List>    
        </Grid>

        <Grid item xs={6}>
            <Typography variant="h6" gutterBottom>Grupos a Agregar:</Typography>
            <List>
                {selectedGruposCart.map((group) => (
                    <ListItem key={group.id}>
                        <ListItemText primary={group.name} />
                        <ListItemSecondaryAction>
                            <IconButton edge="end" aria-label="delete" onClick={() => removeGroupFromCart(group.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                ))}
            </List> 
        </Grid>
        <Grid item xs={12}>
            <Button variant="contained" color="primary" size="large" onClick={handleSave}>
                Guardar
            </Button>
        </Grid>

    </Grid>


    </Paper>
);
}

export default UserPermissions;
