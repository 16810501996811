import React, { useState } from 'react';
import DataUser from './components/DataUser';
import UserPermissions from './components/UserPermissions';

function ManageRolesUser() {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const handleUserAction = (userId, action) => {
    setSelectedUserId(userId);
    setSelectedAction(action);
  };

  return (
    <div>
      <DataUser onUserAction={handleUserAction} />
      {selectedAction === 'ver' && (
        <UserPermissions userId={selectedUserId} /> // Muestra el componente UserPermissions al hacer clic en "Ver Permisos"
      )}
    </div>
  );
}

export default ManageRolesUser;
