import React, { useState } from 'react';
import Grupos from './components/Grupos';
import GroupPermissions from './components/GroupPermissions';
 

export default function ManageRolesGroups() {
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const handleGroupSelected = (id) => {
    setSelectedGroupId(id);
  };

  return (
    <div>
      <h1>Administración de permisos</h1>

      {/* Componente para administrar grupos y seleccionar un grupo */}
      <Grupos onGroupSelected={handleGroupSelected} />

      {/* Si se selecciona un grupo, se muestra el componente GroupPermissions */}
      {selectedGroupId && (
        <GroupPermissions groupId={selectedGroupId} />
      )}
    </div>
  );
}
