import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box } from '@mui/material';

const NotFound = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const handleGoHome = () => {
        navigate.push('/');
    }

    return (
        <Container maxWidth="sm">
            <Box textAlign="center" py={5}>
                <Typography variant="h3" gutterBottom>
                    404 - Página no encontrada
                </Typography>
                <Typography variant="h6">
                    La ruta <code>{location.pathname}</code> no existe.
                </Typography>
                <Box mt={3}>
                    <Button variant="contained" color="primary" onClick={handleGoHome}>
                        Regresar al inicio
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default NotFound;
