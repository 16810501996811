import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import apiInstance from "../../../../services/kalel/ServiceApi";

function DataUser({ onUserAction }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    apiInstance.get("/usuario/users/")
      .then((response) => {
        setUsers(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener los usuarios", error);
        setLoading(false);
      });
  }, []);

  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "name",
      headerName: "Nombre",
      width: 200,
      valueGetter: (params) => `${params.row.persona.first_name} ${params.row.persona.last_name}`,
    },
    {
      field: "dni",      headerName: "DNI",      width: 120,
      valueGetter: (params) => `${params.row.persona.dni}`,
    },
    { field: "username", headerName: "Usuario", width: 150 },
    { field: "is_active", headerName: "Activo", width: 90 },
    { field: "is_superuser", headerName: "Superusuario", width: 90 },
    {
      field: "actions",
      headerName: "Acciones",
      width: 300,
      sortable: false,
      renderCell: (params) => (
        <div>
          <button onClick={() => handleButtonClick(params.row.id, 'ver')}>Ver Permisos</button>
          <button onClick={() => handleButtonClick(params.row.id, 'editar')}>Editar</button>
          <button onClick={() => handleButtonClick(params.row.id, 'eliminar')}>Eliminar</button>
        </div>
      ),
    },
  ];

  const handleButtonClick = (userId, action) => {
    onUserAction(userId, action);
  };

  return (
    <div style={{ height: 400, width: "100%" }}>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <DataGrid
          rows={users}
          columns={columns}
          pageSize={10}
          autoHeight
          checkboxSelection={false}
          disableSelectionOnClick
        />
      )}
    </div>
  );
}

export default DataUser;
