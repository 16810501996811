import { Button, Grid, Paper, TextField, Typography } from '@mui/material'
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { Box } from '@mui/system'
import React from 'react'
 

const styles = {
     
    centerDiv:{
        display: 'flex',
        alignItems: 'center',                            
        justifyContent: 'center',
        height: '150px'
    },
    divLeft:{
        display: 'flex',
        alignItems: 'left',                            
        justifyContent: 'left',
        height: '200px',
        paddingLeft:'20px',
        paddingTop:'25px'
    },
    centerText:{
        display: 'flex',
        alignItems: 'center',                            
        justifyContent: 'center',
        
    } ,
    datos:{
        paddingLeft:'30px',
        paddingTop:'5px'
        
    }
};
const handleClickActualizar = (idt) => {
   console.log("actualizaar")
    //serviceUser.eliminarTarjeta(idt)
    
  }
const FormularioTarjeta = (props) => {

    var edit;
  if (props.login){
   edit=<Grid container spacing={1} direction="row" alignItems="center" justifyContent="center">
            <Grid item container xs={12} sm={8} md={8}>
                <Grid item container xs={12} sm={12} md={12} direction="column" alignItems="center" >
                    <TextField label="Empresa" id="txtEmpresa"  defaultValue={props.tarjeta.empresa} variant="filled" fullWidth  />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TextField label="Distrito" id="txtDistrito"  defaultValue={props.tarjeta.distrito} variant="filled" fullWidth  />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField label="Nombres" id="txtNombre"  defaultValue={props.tarjeta.nombres} variant="filled" fullWidth  />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField label="Apellidos" id="txtApellido"  defaultValue={props.tarjeta.apellidos} variant="filled" fullWidth  />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField label="Documento" id="txtTipoDoc"  defaultValue="DNI" variant="filled" fullWidth  />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField label="Numero Documento" id="txtDni"  defaultValue={props.tarjeta.dni} variant="filled" fullWidth  />                    
                </Grid>
                 
                <Grid item xs={12} sm={4}>
                    <TextField label="Fecha Fin" id="txtFechaFin"  defaultValue={props.tarjeta.fecha_fin} variant="filled" fullWidth  />                    
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField label="Turno" id="txtTurno"  defaultValue={props.tarjeta.turno} variant="filled" fullWidth  />
                </Grid>
                <Grid item xs={12} sm={4}>
                {
                        props.tarjeta.state ? 
                        <TextField label="Estado" id="filled-size-small"  defaultValue='ACTIVO' variant="filled" fullWidth  />                    

                        :
                        <TextField label="Estado" id="filled-size-small"  defaultValue='DESACTIVADO' variant="filled" fullWidth  />                    

                        }
                </Grid>
            </Grid>
             
            <Grid item container xs={12} sm={8} md={8}>
            <Button variant="contained" color="success" onClick={()=> handleClickActualizar(props.tarjeta.id)}><ModeEditIcon/>ACTUALIZAR</Button>

             </Grid>
            </Grid>
  }
  return (<>
    
    <Box display="flex" justifyContent="center"  alignItems="center" pt={2}>
        <Grid container spacing={2} direction="row"   alignItems="center" justifyContent="center">
                
                    <Paper style={{height: 649,width: 400,backgroundImage: `url(${props.tarjeta.fondo})`}}>
                    <div style={{ height:'110px'}}> </div>
                    <div style={styles.centerDiv}>                            
                            <img src={props.tarjeta.foto} alt="img" loading="lazy" style={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4,
                                        display: 'block',  width: '150px' }}/>                                     
                    </div>
                    
                    <div style={styles.datos}>
                        <Typography variant="h8" component="h5"> Apellidos </Typography>  
                        <Typography variant="h8" component="h6"> {props.tarjeta.apellidos} </Typography>  
                        <Typography variant="h8" component="h5"> Nombres </Typography>  
                        <Typography variant="h8" component="h6"> {props.tarjeta.nombres}  </Typography>  
                        <Typography variant="h8" component="h5"> DNI </Typography>  
                        <Typography variant="h8" component="h6"> {props.tarjeta.dni} </Typography>  
                        <Typography variant="h8" component="h5"> NRO. CARNÉ </Typography>  
                        <Typography variant="h8" component="h6"> {props.tarjeta.codigo} </Typography>  
                        <Typography variant="h8" component="h5"> Persona Juridica </Typography>  
                        <Typography variant="h8" component="h6"> {props.tarjeta.empresa} </Typography>  
                            
                    </div>
                    
                    <div style={styles.datos}>
                        <Typography variant="h8" component="h4"> Vigencia Hasta </Typography>  
                        <Typography variant="h8" component="h5"> {props.tarjeta.fecha_fin}  </Typography> 
                    </div>
                    <div style={styles.centerText}> 
                    {
                        props.tarjeta.state ? 
                            <Typography variant="h8" component="h3" style={{ color: 'green' }}>
                                ACTIVO
                            </Typography>
                        :
                            <Typography variant="h8" component="h3" style={{ color: 'red' }}>
                                DESACTIVADO
                            </Typography>
                        }
      
                        </div>
                    <div style={styles.divLeft}>                            
                            <img src={props.tarjeta.qr} alt="img" loading="lazy" style={{borderBottomLeftRadius: 4, borderBottomRightRadius: 4,
                                display: 'block',  width: '150px' ,height:'150px'}}/>                                     
                    </div>
                  
                    </Paper>
        </Grid>
        {edit}
    </Box>
    </>

  )
}

export default FormularioTarjeta