import { Button, Grid, TextField } from '@mui/material'
import { Box, Container } from '@mui/system'
import React from 'react'
import GoogleAd from '../../components/adsense/AdsGrafico'
import apiInstance from '../../services/kalel/ServiceApi'
import FormularioTarjeta from '../tarjetas/FormularioTarjeta'

export const Consultas = () => {
    const [dataTarjeta, setDataTarjeta] = React.useState([]);
    const [loadTarjeta, setLoadTarjeta] = React.useState(false);

    let listarTarjetas = async () => {
        let params = {}
        let dni = document.getElementById('ctxtdni').value;
 
        if (dni.length>0){params['dni']=dni}
 
        apiInstance.post('consultas/consultatarjeta',params)
        .then(res => {
            setDataTarjeta(res.data)
            setLoadTarjeta(true)
            
        })
        .catch((error) => {
            console.log(error);
        });
      }
    var data = ""
    if(dataTarjeta && loadTarjeta){
        
        data = dataTarjeta.map((con,i) => (        
        <FormularioTarjeta key = {i} tarjeta={dataTarjeta[i]}></FormularioTarjeta>
        ))}

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>        
          <Container fixed>
          <img
                src={`https://api.kalelsac.com/media/banner.png`}
                 
                srcSet={`${`https://api.kalelsac.com/media/mediobanner.png`} 320w, ${`https://api.kalelsac.com/media/mediobanner.png`} 680w, ${`https://api.kalelsac.com/media/mediobanner.png`}   960w, ${`https://api.kalelsac.com/media/banner.png`} 1980w`}
                alt='banner'
                loading="lazy"
            />
            <Grid container spacing={2} direction="column">
             <Grid item container xs={12} sm={12} md={12} spacing={1} direction="row" 
                  alignItems="center" justifyContent="center"  >
                  <Grid item xs={8}>
                    <TextField id="ctxtdni" label="Dni" variant="outlined" />
                  </Grid>                  
                  <Grid item xs={1}>
                    <Button variant="contained" onClick={()=>{            
                        listarTarjetas() }}>BUSCAR</Button>
                  </Grid>                  
              </Grid>
              <Grid item container xs={12} sm={12} md={12} spacing={1} direction="row" 
                  alignItems="center" justifyContent="center"  >
                  {data}       
              <GoogleAd slot="1495310928" googleAdId="ca-pub-4817534541991617"/>

              </Grid>
            
            </Grid>
           
            </Container>
       
       
          </Box>
  )
}
