import { Box, Button, Divider, FormControl, Grid,  InputLabel,  MenuItem,  Select,  TextField } from '@mui/material'
import React, { Component } from 'react'
import SelectVariants from '../../components/select';
import MyDataGrid from './componentes/MyDataGrid';
import LinearProgress from '@mui/material/LinearProgress';
import Utilities from '../../services/kalel/Utilities';
import apiInstance from '../../services/kalel/ServiceApi';
import Mensajeria from '../../components/Mensajeria';
import UserCursoVial from '../../components/UserCursoVial';

class ListTarjeta extends Component{
  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        logueado :Utilities.userSession(),
        loadTarjetas:false,
        flag : true,
        dataDistrito: false,
        dataTarjeta: [],
        idDistritoEmpresa:false,
        idDistrito:null,
        loadingDistrito:false   ,
        open : false,
        estadoTitulo: true

    }
    }

    componentDidMount() {
    
      this.listarDistrito(); 
    } 
    listarDistrito() {
      apiInstance.get('usuario/distrito')
      .then(res => {
          this.setState({dataDistrito: res.data});
      })
      .catch((error) => {
          console.log(error);
      });
    }
    handleChangeEstadoTitulo = (event) => {
      this.setState({ estadoTitulo: event.target.value });
    };
    handleOpen() {
      this.setState({open: true});
    }
    handleClose() {
      this.setState({open: false});
    }
    listarTarjetas() {
      this.setState({loadTarjetas:false,loading:true});

      let params = {
        estado:this.state.estadoTitulo
      }
      let dni = document.getElementById('txtdni').value;
      let nombre = document.getElementById('txtnombre').value;
      let apellido = document.getElementById('txtapellido').value;
      if (dni.length>0){params['dni']=dni}
      if (nombre.length>0){params['nombre']=nombre}
      if (apellido.length>0){params['apellido']=apellido}
      if (this.state.idDistritoEmpresa){params['empresa_distrito']=this.state.idDistritoEmpresa.idEmpDis    }
      else if(this.state.idDistrito){params['distrito']=this.state.idDistrito.idDistrito}
      else{
        return false
      }
      apiInstance.get('usuario/tarjeta',{'params':params})

      .then(res => {
          this.setState({dataTarjeta: res.data,loadTarjetas:true,loading:false});
          
      })
      .catch((error) => {
          console.log(error);
      });
     
       
    }
    exportTarjeta() {
      this.setState({loadTarjetas:false,loading:true});

      let params = {}     
      if (this.state.idDistritoEmpresa){params['empresa_distrito']=this.state.idDistritoEmpresa.idEmpDis    }
      else if(this.state.idDistrito){params['distrito']=this.state.idDistrito.idDistrito}
      else{
        console.log("no selecciono distrito")
        return false
      }
      apiInstance.post('reporteTarjeta',params)
      .then(res => {
        console.log(res)
           
          this.setState({loadTarjetas:true,loading:false});
          var data = new Blob([res.data], {type: 'text/csv'});
          var csvURL = window.URL.createObjectURL(data);
          var tempLink = document.createElement('a');
          tempLink.href = csvURL;
          tempLink.setAttribute('download', 'reporte.xlsx');
          tempLink.click();
      })
      .catch((error) => {
          console.log(error);
      });
     
       
    }
    CleanForm(object1){
      for (const [key, value] of Object.entries(object1)) {
        document.getElementById(value).value=''; 
       }
    }
   
    getResponse(resultado){
      
        this.setState({idDistritoEmpresa:resultado})

    }
    getResponseDistrito(resultado){
      console.log(resultado,"distrito")
      this.setState({idDistrito:resultado,flag:false})
      if (resultado==null){
        this.setState({flag:true})

      }

    }
    
    render(){
      var dataselect;
      var datatable;  
      const estadoTituloSelect = (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="estado-titulo-label">Estado del título</InputLabel>
          <Select
            labelId="estado-titulo-label"
            id="estado-titulo-select"
            value={this.state.estadoTitulo}
            onChange={this.handleChangeEstadoTitulo}
            label="Estado del título"
          >
            <MenuItem value={true}>Solo Activos</MenuItem>
            <MenuItem value={false}>Todos</MenuItem>
          </Select>
        </FormControl>
      );
      if(this.state.logueado===false){
        window.location.href="./login" 
      } 
      if(this.state.dataDistrito){ 
          dataselect =         
          <SelectVariants lista = {this.state.dataDistrito} callbackDistrito ={this.getResponseDistrito.bind(this)} callback = {this.getResponse.bind(this)}/> 
      }

      if(this.state.loadTarjetas){ 
          datatable = <MyDataGrid lista = {this.state.dataTarjeta}></MyDataGrid> 
      }
          
      if(this.state.loading){
        datatable=<LinearProgress/>
      }  
        return (
          <>
          <Box sx={{ flexGrow: 1 ,padding:'2%'}}>
            <Grid container spacing={0} direction="row"  alignItems="center" justifyContent="center">              
                <Grid item xs={12} sm={12} md={12} >
                  {dataselect}
                </Grid>
                <Grid item >
                   {estadoTituloSelect}
                </Grid>
                <Grid item >
                  <TextField id="txtdni" label="Dni" variant="outlined" onChange={()=>{this.CleanForm(['txtnombre','txtapellido'])}} />
                </Grid>
                <Grid item >
                  <TextField id="txtnombre" label="Nombres" variant="outlined" onChange={()=>{this.CleanForm(['txtdni'])} } />
                </Grid>
                <Grid item >
                    <TextField id="txtapellido" label="Apellidos" variant="outlined" onChange={()=>{this.CleanForm(['txtdni'])}} />
                </Grid>
              </Grid>
              <Grid container spacing={0} direction="row"
                alignItems="center" justifyContent="center"> 
                <Grid item >
                      <Button variant="contained" disabled={this.state.flag}  onClick={()=>{this.listarTarjetas()}}>BUSCAR</Button>              
                      <Button variant="contained" disabled={this.state.flag} onClick={()=>{  this.exportTarjeta() }}>EXPORTAR</Button> 
                      <Button variant="contained" disabled={this.state.flag} onClick={()=>{  this.handleOpen() }}  >Agregar</Button>


                </Grid>
            </Grid>
          </Box>
          <Divider/>
            <Box sx={{ bgcolor: '#ffff', height: '100vh' }} > {datatable}
            
            <UserCursoVial distrito={this.state.idDistrito} empresa={this.state.idDistritoEmpresa} open={this.state.open} onClose={()=>{  this.handleClose() }}/>
            
            </Box>
            <Mensajeria/>
          </>
       
          )

    }
}


export default ListTarjeta