// useAxios.js
import { useState, useEffect } from 'react';
import axios from 'axios';
import Utilities from '../services/kalel/Utilities';
import {API_BASE_URL_KALEL} from '../services/config';
const useAxios = (method, api,params,refresh) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    let mounted = true;
    setLoading(true);
    const requestInterceptor = axios.interceptors.request.use(config => {
      
      if(config.url==='reporteTarjeta'){
        config.responseType = 'blob'

      }
      const token = Utilities.getToken()
      if (token) {
        config.headers['Authorization'] = token
      }
      return config;


    }, error => {
      return Promise.reject(error);
    });
     // Interceptor de respuesta
    const responseInterceptor = axios.interceptors.response.use(response => {
        return response;
      }, error => {
        if (error.response && error.response.status === 401) {
          // Si la respuesta tiene un estado 401 (no autorizado), redireccionar a la página de inicio de sesión
          window.location.href = '/login';
        } else {
          // En cualquier otro caso, lanzar el error
          return Promise.reject(error);
        }
      });

    axios({
        method: method,
        url: `${API_BASE_URL_KALEL}/${api}`,
        params: params // Añade 'params' aquí
      })
    .then(res => {
    if (mounted) {
      setData(res.data);
      setLoading(false);
      }
    })
    .catch(err => {
      if (mounted) {
      setError(err);
      setLoading(false);
      }
    });


    return () => {
      mounted = false;
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
    }, [method, api, refresh]);

    return { data, loading, error };
    };

  export default useAxios;