import { Box, Button, Grid,  TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import ToastMessagge from '../components/ToastMessagge';
import apiInstance from '../services/kalel/ServiceApi'
import Utilities from '../services/kalel/Utilities';
class LoginForm extends Component{
    state= {
        session:Utilities.userSession(),
        domain:window.location.host.split(".").filter((item) => item !== 'www')[0],
        openToast:false,
        messagge:{},       
        form:{
            username: '',
            password: '',
        }
    }
    handleChange = async e => {
      const { name, value } = e.target;
      await this.setState(prevState => ({
        form: {
          ...prevState.form,
          [name]: value
        }
      }));
  
      if (['aulavirtual', 'evaluacion'].includes(this.state.domain)) {
        this.setState(prevState => ({
          form: {
            ...prevState.form,
            password: prevState.form.username
          }
        }));
      }
    }
    handleCloseToast = () => this.setState({ openToast: false });
    iniciarSession = async () => {
      try {
        const _domain = this.state.domain;
        localStorage.setItem('dominio', _domain);
    
        const response = await apiInstance.post('usuario/login', this.state.form);
        const { token, tipo_usuario, modulos, perfil, usuario } = response.data;
    
        localStorage.setItem('token', JSON.stringify(token));
        localStorage.setItem('tipo_usuario', JSON.stringify(tipo_usuario));
        localStorage.setItem('modulos', JSON.stringify(modulos));
        localStorage.setItem('perfil', JSON.stringify(perfil));
        localStorage.setItem('usuario', JSON.stringify(usuario));
    
        const resPermiso = await apiInstance.get('user_permissions/' + usuario.id + '/');
        localStorage.setItem('permisos', JSON.stringify(resPermiso.data));
    
        this.setState({ severity: "success", messagge: { "logueo": "exitoso" }, openToast: true });
    
        switch (_domain) {
          case 'aulavirtual':
            window.location.href="./miaula"

            break;
          case 'cursovial':
            window.location.href="./tarjeta"

            break;
          case 'evaluacion':
          default:
            window.location.href="./"
            break;
        }
    
      } catch (error) {
        console.log("error", error);
        this.setState({ severity: "error", messagge: error, openToast: true });
      }
    }
         
        
     
    getImageSrc = () => {
      const domainImages = {
        aulavirtual: 'https://api.kalelsac.com/media/logo_ojo.png',
        cursovial: 'https://api.kalelsac.com/media/logo_ojo.png',
        evaluacion: 'https://api.kalelsac.com/media/centroevaluacion.png'
      };
      localStorage.setItem('domainImage',domainImages[this.state.domain] || 'https://api.kalelsac.com/media/logo_ojo.png')

      return domainImages[this.state.domain] || 'https://api.kalelsac.com/media/logo_ojo.png';
    }
  
    getTitulo = () => {
      const domainTitles = {
        aulavirtual: 'Aula Virtual',
        cursovial: 'Curso Vial',
        evaluacion: 'CENTRO DE EVALUACIÓN KAL-EL S.A.C.',
        admin: 'KAL-EL S.A.C. Administración'
      };
      localStorage.setItem('domain_titulo',domainTitles[this.state.domain] || 'KALEL SAC')

      return domainTitles[this.state.domain] || 'KALEL SAC';
    }
    render()
    {
        return (
              <Grid  
              container 
              spacing={0}
              direction="row"
              alignItems="center"
              justifyContent="center"
              > 
                <Grid   item xs={12} alignItems="center" justifyContent="center" textAlign="center" style={{ display: 'flex' }}>
                <img
                          src={this.getImageSrc()}
                          alt='banner'
                          loading="lazy"
                      />
              </Grid>
              <Grid item xs={12} alignItems="center" justifyContent="center" textAlign="center">
                    <Typography color="success" variant="h6" sx={{
                            fontSize: '2rem',}}>
                           Inicio de Sesión
                      </Typography>
              </Grid>

              <Grid container item xs={12} direction="column"  alignItems="center"  justifyContent="center"
              >
               <Box sx={{background:'#e7edf4',padding:'50px', borderRadius: '16px'}}>
              
                <Grid container item xs={12} 
                  spacing={3}
                  direction="column"
                  alignItems="center"
                  justifyContent="center" 
                > 
                   {this.state.domain!=='aulavirtual'&&                   
                   <Grid item xs={8}>
                      <h1 style={{ color: "green", fontFamily: "Arial", fontSize: "1.5rem" }}>
                          {this.getTitulo()}
                      </h1>
                  </Grid>
                }                  
                  <Grid item xs={8} >
                    <TextField 
                        name='username' 
                        label="Usuario" 
                        placeholder='Ingrese su usuario'
                        onChange = {this.handleChange}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                              this.iniciarSession();
                          }
                      }}
                        />
                  </Grid>
                  {this.state.domain!=='aulavirtual'&& this.state.domain!=='evaluacion'&& 
                  <Grid  item xs={8}
                     >
                    <TextField 
                        name='password' 
                        label="Contraseña" 
                        type={'password'}
                        placeholder='ingrese su contraseña'
                        onChange = {this.handleChange}
                        
                        onKeyDown={(e)=> {
                          if(e.key==='Enter'){
                            this.iniciarSession()
                          }
                          }}
                        
                        />
                  </Grid>}
        
                  <Grid item xs={8} 
                     >          
                      <Button color='success' variant="contained" onClick={()=> this.iniciarSession()} style={{ width: '100%', height: '60px', fontSize: '24px' }}>
                        INGRESAR
                      </Button>
                  {this.state.openToast && 
                  <ToastMessagge severity={this.state.severity} open={this.state.openToast} msg={this.state.messagge} onClose={()=>this.handleCloseToast()}/>}
                  </Grid>
                </Grid>
                </Box>
              </Grid>
              </Grid>
          )
    }
}
export default LoginForm