import React , { lazy, Suspense }from 'react'
import { 
    Routes,
    Route, 
} from 'react-router-dom'
 
import LoginForm from '../pages/Login'
 
 
import { commonRoutes ,routesForHostname} from './rutasConfig';
 

const Rutas = () => {
  const hostname = window.location.hostname;
 
  //const MiAula = lazy(() => import('../pages/aula/MiAula'));
  //const LoadTarjetaOyC = lazy(() => import('../pages/admin/oyccoaching/loadTarjeta/index'));
  
  
  return (
    <Suspense fallback={<div>Loading...</div>}>
        <Routes>
            {commonRoutes}
            {routesForHostname[hostname] || (
                <>
                    <Route path="/login" element={<LoginForm />} />
                </>
            )}
        </Routes>
    </Suspense>
);

}
export default Rutas
