import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { Grid } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
 
export default function ToastMessagge(props) {
  const [open, setOpen] = useState(props.open);
  const [mensaje, setMensaje] = useState([]);

  const handleClose = (event, reason) => { 
    console.log(event,reason,"cerrar")
    props.onClose();
  };
  function handleStatusChange() {
    var msgList=[]
    Object.entries(props.msg).forEach(([k,v]) => {          
      msgList.push( k+" : "+ v)
      })
    setMensaje(msgList)
    setOpen(true)
  }
  React.useEffect(() => {
    
    handleStatusChange()
             
  },[open]);
  //error,warning,info,success
  return (
    <Stack spacing={2} sx={{ width: '100%' }}>    
      <Grid   style={{zIndex:1 ,position:'fixed'}}> 
        
      {mensaje && mensaje.map((con,i) => ( 
        <Grid item key={i} >
          <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>    
            <Alert onClose={handleClose} severity={props.severity} sx={{ width: '100%' }}>
                    {con} 
            </Alert>
          </Snackbar>
        </Grid>
              ))}
      
      </Grid>
      </Stack>
   
  
  );
};

