import React, { useEffect, useState } from 'react';
import { FormControl, Autocomplete, InputLabel, TextField } from '@mui/material';
import useAxios from '../../hooks/UseApi';

const Servicio = ({ onSelectionChange, selectedValue }) => {
  const [selected, setSelected] = useState(selectedValue || null);

  const { data, loading, error } = useAxios("get", "service", {});
  console.log(selectedValue)
  useEffect(() => {
    // Encuentra el objeto que tenga un id igual a selectedValue
    const foundValue = data?.find((item) => item.id === selectedValue) || null;
    setSelected(foundValue);
  }, [selectedValue, data]);
  // Actualiza el estado de 'selected' cuando 'selectedValue' cambia
  useEffect(() => {
    setSelected(selectedValue);
  }, [selectedValue]);
  const handleChange = (event, newValue) => {
    console.log(selected,newValue)
    setSelected(newValue);
    onSelectionChange(newValue);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (error) {
    return <div>Ocurrió un error al cargar los datos</div>;
  }

  return (
    <FormControl fullWidth={true}>
      <Autocomplete
        id="slc_servicio"
        options={data}
        getOptionLabel={(option) => `${option.id} - ${option.titulo}`}
        value={selected}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} label="Servicio" />}
      />
    </FormControl>
  );
};

export default Servicio;
