import axios from "axios";
import Utilities from "./Utilities"
import {API_BASE_URL_KALEL} from '../config';

const apiInstance = axios.create({baseURL:API_BASE_URL_KALEL});

apiInstance.interceptors.request.use(
    (request) =>{
        if(request.url==='user/login'){
            return request;
        }
        else if(request.url==='reporteTarjeta'){
            request.responseType = 'blob'
        }
        
        request.headers['Authorization'] = Utilities.getToken()
        return request;
    },
    (err) =>err

);
apiInstance.interceptors.response.use(
    (response)=>response,
    (err)=>{
        if(!err.response){
            return Promise.reject({
                status:404,
                timestamp:new Date(),
                message:"Se ha producido un error no contemplado",
                error:"Error de servidor"

            });
        }
        else if(!err.response.data){
            
                return Promise.reject({
                    status:404,
                    timestamp:new Date(),
                    message:"Se ha producido un error no contemplado",
                    error:"Error de servidor"
    
                });
        }
        return Promise.reject(err.response.data);
    }
)
export default apiInstance;