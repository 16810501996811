import React, { useState } from 'react';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import {  NavLink  } from 'react-router-dom'
import AddCardIcon from '@mui/icons-material/AddCard';
import BuildIcon from '@mui/icons-material/Build';
import SchoolIcon from '@mui/icons-material/School';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GetPermisos from '../../hooks/GetPermisos';
import { ListSubheader } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
export const MainListItems = ({ domain }) => {
  const { hasPermission } = GetPermisos();
  const [openLoadFiles, setOpenLoadFiles] = useState(false);
  const [openReserva, setOpenReserva] = useState(false);
  const dominio = domain.replace("www", "")
               .replace("kalelsac.com", "")
               .replace(".", "")
               .replace(":3000", "")
               .replace("localhost", "");

  const handleClickLoadFiles = () => {
          setOpenLoadFiles(!openLoadFiles);
    };
  
  const handleClickReservaCita = () => {
    setOpenReserva(!openReserva);
};

  return (
    <React.Fragment>
     {dominio === 'aulavirtual' && (
        <NavLink to={"/miaula"}>
          <ListItemButton>
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Mi Aula" />
          </ListItemButton>
        </NavLink>
      )}
      {dominio === "cursovial" && (
        <React.Fragment>
        <NavLink to={"/miaula"}>
          <ListItemButton >
            <ListItemIcon>
              <SchoolIcon />
            </ListItemIcon>
            <ListItemText primary="AULA VIRTUAL" />
          </ListItemButton>
        </NavLink>
        </React.Fragment>
      )}
      {dominio === "admin" &&(
        <>
        {hasPermission('/dashboard') && (
          <NavLink to={"/"}>
            <ListItemButton>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
          </NavLink>
        )}
      
        {hasPermission('/reserva_citas') && (
          <React.Fragment>
            <ListItemButton onClick={handleClickReservaCita}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
              <ListItemText primary="Reservar citas" />
              {openReserva ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openReserva} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasPermission('res') && (
                  <NavLink to={'/reservaCita'}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                          <AccessTimeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Listado de reservas" />
                    </ListItemButton>
                  </NavLink>
                )}
                {hasPermission('res') && (
                  <NavLink to={'/config-reserva'}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                          <AccessTimeIcon />
                      </ListItemIcon>
                      <ListItemText primary="Configurar Dias Disponibles" />
                    </ListItemButton>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </React.Fragment>
        )}
        {hasPermission(198) && (
          <NavLink to={"/evaluacion"}>
            <ListItemButton>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Evaluacion" />
            </ListItemButton>
          </NavLink>
        )}
        {hasPermission(198) && (
          <NavLink to={"/examenes"}>
            <ListItemButton>
              <ListItemIcon>
                <SchoolIcon />
              </ListItemIcon>
              <ListItemText primary="Examenes" />
            </ListItemButton>
          </NavLink>
        )}
        {hasPermission('tarjeta') && (
          <NavLink to={"/tarjeta"}>
            <ListItemButton>
              <ListItemIcon>
                <AddCardIcon />
              </ListItemIcon>
              <ListItemText primary="Curso VIal" />
            </ListItemButton>
          </NavLink>
        )}
        {hasPermission('caja') && (
         <NavLink to={"/caja"}>
          <ListItemButton>
            <ListItemIcon>
              <CurrencyExchangeIcon />
            </ListItemIcon>
            <ListItemText primary="Caja" />
          </ListItemButton>
        </NavLink>
        )}
        {hasPermission('ManageRoles') && (
           <NavLink to={"/ManageRoles"}>
           <ListItemButton>
             <ListItemIcon>
               <BuildIcon />
             </ListItemIcon>
             <ListItemText primary="ManageRoles" />
           </ListItemButton>
         </NavLink>
        )}
         {hasPermission('constatacion') && (
           <NavLink to={"/constatacion"}>
           <ListItemButton>
             <ListItemIcon>
             <AddCardIcon />
             </ListItemIcon>
             <ListItemText primary="Constatación" />
           </ListItemButton>
         </NavLink>
        )}

        {hasPermission('loadFiles') && (
          <React.Fragment>
            <ListItemButton onClick={handleClickLoadFiles}>
              <ListItemIcon>
                <CloudUploadIcon />
              </ListItemIcon>
              <ListItemText primary="O&C Coaching" />
              {openLoadFiles ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openLoadFiles} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {hasPermission('LoadTarjetaOyC') && (
                  <NavLink to={"/LoadTarjetaOyC"}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                         <CloudUploadIcon />
                      </ListItemIcon>
                      <ListItemText primary="Carga Tarjetas O&C Coaching" />
                    </ListItemButton>
                  </NavLink>
                )}
                {hasPermission('LoadTarjetaOyC') && (
                  <NavLink to={"/consultasOyC"}>
                    <ListItemButton sx={{ pl: 4 }}>
                      <ListItemIcon>
                         <SearchIcon />
                      </ListItemIcon>
                      <ListItemText primary="Consulta Carné" />
                    </ListItemButton>
                  </NavLink>
                )}
              </List>
            </Collapse>
          </React.Fragment>
        )}
      
      </>
      )}
      
    </React.Fragment>
  );
};
export const SecondaryListItems = (props) => {
  return (
    <>
      <ListSubheader component="div" inset>
        Saved reports
      </ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <BuildIcon />
        </ListItemIcon>
        <ListItemText primary="Current month" />
      </ListItemButton>
       
    </>
  );
};
